
    @import "config.scss";
 
.form {
  height: 0px;
  overflow: hidden;
  transition: all ease-in-out 0.25s;
  display: flex;
  height: auto;
  flex-direction: column;
}

.container {
  margin-bottom: 1rem;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
