
    @import "config.scss";
 
.cart {
  position: relative;

  @include for-desktop-up {
    // flex: 1;
    width: 10rem;
  }
}

.searchbar {
  width: 60%;
}

.itemCount {
  background-color: white;
  border-radius: 50px;
  height: 1rem;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.1rem;
  right: -0.75rem;
  cursor: pointer;
  padding: 0.6rem;

  @include for-desktop-up {
    left: 1.5rem;
    // height: 60%;
    // width: 16%;
    top: -0.3rem;
    // padding: 0.6rem;
  }
}
